@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  font-family: Optimistic Display, -apple-system, ui-sans-serif, system-ui,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

.bg-main {
  --tw-bg-opacity: 1;
  background-color: rgb(35 39 47 / var(--tw-bg-opacity));
}

.font-2xl {
  font-size: 24px;
}

::before,
::after {
  box-sizing: content-box;
}

.pre-font {
  font-family: monospace;
}

.-ml-2-px {
  margin-left: -2px;
}
